import {
    type ButtonHTMLAttributes,
    type ReactNode,
    type JSX,
    forwardRef,
} from "react";
import styled, {
    type FlattenSimpleInterpolation,
    css,
} from "styled-components";

import { defaultFocusOutlineMixin } from "@/Support/defaultFocusOutlineMixin";

import { SpinLoader } from "@/Components/SpinLoader";
import { Text } from "@/Components/Text";

export type ButtonKind =
    | "action"
    | "dark"
    | "destructive"
    | "ghost-reverse"
    | "ghost"
    | "primary"
    | "secondary-reverse"
    | "secondary"
    | "success"
    | "warning";

export type ButtonSize = "large" | "medium" | "small";

const Loader = styled(SpinLoader)`
    position: absolute;
`;

const Content = styled.span`
    align-items: center;
    display: flex;
    gap: 7px;
    justify-content: center;
`;

export type ButtonStyleProps = {
    $size?: ButtonSize;
    $square: boolean;
    $loading: boolean;
    $disabled: boolean;
    $selected: boolean;
};

export const buttonStyle = css<ButtonStyleProps>`
    align-items: center;
    appearance: none;
    cursor: ${({ $loading, $disabled, $selected }): string =>
        $loading
            ? "progress"
            : $disabled
              ? "not-allowed"
              : $selected
                ? "auto"
                : "pointer"};
    display: flex;
    font-weight: 500;
    justify-content: center;
    position: relative;
    transition:
        color 0.2s,
        background 0.2s,
        border-color 0.2s;
    ${({ $size }) =>
        $size === undefined &&
        css`
            ${Text.style.Body}
            border-radius: 4px;
        `}
    ${({ $size, $square }) =>
        $size === "small" &&
        css`
            ${Text.style.Body}
            border-radius: 4px;
            max-height: 32px;
            min-height: 32px;
            ${$square
                ? css`
                      max-width: 32px;
                      min-width: 32px;
                  `
                : css`
                      padding: 0 8px;
                  `}
        `}
    ${({ $size, $square }) =>
        $size === "medium" &&
        css`
            ${Text.style.Body}
            border-radius: 4px;
            max-height: 44px;
            min-height: 44px;
            ${$square
                ? css`
                      max-width: 44px;
                      min-width: 44px;
                  `
                : css`
                      padding: 0 16px;
                  `}
        `}
    ${({ $size, $square }) =>
        $size === "large" &&
        css`
            ${Text.style.BodyL}
            border-radius: 8px;
            max-height: 58px;
            min-height: 58px;
            ${$square
                ? css`
                      max-width: 58px;
                      min-width: 58px;
                  `
                : css`
                      padding: 16px 24px;
                  `}
        `}
    ${defaultFocusOutlineMixin}
    width: fit-content;

    & > ${Content} {
        visibility: ${({ $loading }): string =>
            $loading ? "hidden" : "visible"};
    }

    .active {
        cursor: initial;
    }
`;

const disabledStyle = css<{
    $loading: boolean;
    $disabled: boolean;
}>`
    ${({ $disabled, $loading }): FlattenSimpleInterpolation | false =>
        $disabled &&
        css`
            background: var(--color-neutral-500);
            color: ${$loading ? "var(--color-violet-500)" : "white"};
        `}
`;

const BasicButton = styled.button.attrs((props) => ({
    type: props.type ?? "button",
}))`
    ${buttonStyle}
`;

export const primaryStyle = css<{
    $loading: boolean;
    $selected: boolean;
    $disabled: boolean;
}>`
    ${disabledStyle}
    ${({ $disabled, $selected }): FlattenSimpleInterpolation | false =>
        !$disabled &&
        css`
            ${$selected
                ? css`
                      background: var(--color-violet-700);
                      color: var(--color-white);
                  `
                : css`
                      background: var(--color-violet-500);
                      color: var(--color-white);

                      &:hover {
                          background: var(--color-violet-600);
                      }

                      &:active {
                          background: var(--color-violet-700);
                      }

                      &.active {
                          background: var(--color-violet-700);
                          color: var(--color-white);
                      }
                  `}
        `}
`;

export const secondaryStyle = css<{
    $loading: boolean;
    $selected: boolean;
    $disabled: boolean;
}>`
    border: 1px solid;
    color: var(--color-neutral-900);
    ${disabledStyle}
    ${({ $disabled }): FlattenSimpleInterpolation | false =>
        $disabled &&
        css`
            background: var(--color-white);
            border-color: var(--color-neutral-300);
            color: var(--color-neutral-500);
        `}
    ${({ $disabled, $selected }): FlattenSimpleInterpolation | false =>
        !$disabled &&
        css`
            ${$selected
                ? css`
                      border-color: var(--color-pink-500);
                  `
                : css`
                      background: var(--color-white);
                      border-color: var(--color-neutral-300);

                      &:hover {
                          background: var(--color-neutral-100);
                          border-color: var(--color-neutral-200);
                      }

                      &:active {
                          background: var(--color-neutral-200);
                          border-color: var(--color-neutral-200);
                      }

                      &.active {
                          border-color: var(--color-violet-500);
                      }
                  `}
        `}
`;

export const ghostStyle = css<{
    $loading: boolean;
    $selected: boolean;
    $disabled: boolean;
}>`
    ${disabledStyle}
    ${({ $disabled, $selected }): FlattenSimpleInterpolation | false =>
        !$disabled &&
        css`
            ${$selected
                ? css`
                      background: var(--color-neutral-100);
                      color: var(--color-violet-500);
                  `
                : css`
                      color: var(--color-neutral-900);

                      &:hover {
                          background: var(--color-neutral-100);
                      }

                      &:active {
                          background: var(--color-neutral-200);
                      }

                      &.active {
                          background: var(--color-neutral-100);
                          color: var(--color-violet-500);
                      }
                  `}
        `}
`;

export const successStyle = css<{
    $loading: boolean;
    $selected: boolean;
    $disabled: boolean;
}>`
    ${disabledStyle}
    ${({ $disabled, $selected }): FlattenSimpleInterpolation | false =>
        !$disabled &&
        css`
            ${$selected
                ? css`
                      background: var(--color-success-700);
                      color: var(--color-white);
                  `
                : css`
                      background: var(--color-success-500);
                      color: var(--color-white);

                      &:hover {
                          background: var(--color-success-600);
                      }

                      &:active {
                          background: var(--color-success-700);
                      }

                      &.active {
                          background: var(--color-success-700);
                          color: var(--color-white);
                      }
                  `}
        `}
`;

export const destructiveStyle = css<{
    $loading: boolean;
    $selected: boolean;
    $disabled: boolean;
}>`
    ${disabledStyle}
    ${({ $disabled, $selected }): FlattenSimpleInterpolation | false =>
        !$disabled &&
        css`
            ${$selected
                ? css`
                      background: var(--color-destructive-700);
                      color: var(--color-white);
                  `
                : css`
                      background: var(--color-destructive-500);
                      color: var(--color-white);

                      &:hover {
                          background: var(--color-destructive-600);
                      }

                      &:active {
                          background: var(--color-destructive-700);
                      }

                      &.active {
                          background: var(--color-destructive-700);
                          color: var(--color-white);
                      }
                  `}
        `}
`;

export const warningStyle = css<{
    $loading: boolean;
    $selected: boolean;
    $disabled: boolean;
}>`
    ${disabledStyle}
    ${({ $disabled, $selected }): FlattenSimpleInterpolation | false =>
        !$disabled &&
        css`
            ${$selected
                ? css`
                      background: var(--color-warning-700);
                      color: var(--color-white);
                  `
                : css`
                      background: var(--color-warning-400);
                      color: var(--color-neutral-900);

                      &:hover {
                          background: var(--color-warning-500);
                      }

                      &:active {
                          background: var(--color-warning-600);
                          color: var(--color-white);
                      }

                      &.active {
                          background: var(--color-warning-700);
                          color: var(--color-white);
                      }
                  `}
        `}
`;

export const ghostReverseStyle = css<{
    $loading: boolean;
    $selected: boolean;
    $disabled: boolean;
}>`
    ${({ $disabled, $selected, $loading }): FlattenSimpleInterpolation =>
        $disabled
            ? css`
                  color: var(--color-neutral-50);
                  opacity: ${$loading ? "1" : "0.4"};
              `
            : css`
                  ${$selected
                      ? css`
                            background: var(--color-white);
                            color: var(--color-neutral-700);
                        `
                      : css`
                            color: var(--color-neutral-50);

                            &:hover {
                                background: rgb(255 255 255 / 30%);
                            }

                            &:active {
                                background: rgb(255 255 255 / 50%);
                            }

                            &.active {
                                background: var(--color-white);
                                color: var(--color-neutral-700);
                            }
                        `}
              `}
`;

export const secondaryReverseStyle = css<{
    $loading: boolean;
    $selected: boolean;
    $disabled: boolean;
}>`
    border: 1px solid;

    ${({ $disabled, $selected, $loading }): FlattenSimpleInterpolation =>
        $disabled
            ? css`
                  border-color: var(--color-neutral-100);
                  color: var(--color-neutral-50);
                  opacity: ${$loading ? "1" : "0.4"};
              `
            : css`
                  ${$selected
                      ? css`
                            background: var(--color-white);
                            border-color: var(--color-white);
                            color: var(--color-neutral-700);
                        `
                      : css`
                            border-color: var(--color-neutral-300);
                            color: var(--color-neutral-50);

                            &:hover {
                                background: rgb(255 255 255 / 30%);
                                border-color: var(--color-neutral-200);
                            }

                            &:active {
                                background: rgb(255 255 255 / 50%);
                                border-color: var(--color-neutral-200);
                            }
                        `}
              `}
`;

export const actionStyle = css<{
    $loading: boolean;
    $selected: boolean;
    $disabled: boolean;
}>`
    ${({ $disabled, $selected, $loading }): FlattenSimpleInterpolation =>
        $disabled
            ? css`
                  color: ${$loading
                      ? "var(--color-violet-500)"
                      : "var(--color-neutral-400)"};
              `
            : css`
                  ${$selected
                      ? css`
                            background: var(--color-neutral-800);
                            color: var(--color-white);
                        `
                      : css`
                            color: var(--color-violet-500);

                            &:hover {
                                background: var(--color-neutral-100);
                            }

                            &:active {
                                background: var(--color-neutral-200);
                                color: var(--color-blue-700);
                            }

                            &.active {
                                background: var(--color-neutral-800);
                                color: var(--color-white);
                            }
                        `}
              `}
`;

export const darkStyle = css<{
    $loading: boolean;
    $selected: boolean;
    $disabled: boolean;
}>`
    ${({ $disabled, $selected, $loading }): FlattenSimpleInterpolation =>
        $disabled
            ? css`
                  background: ${$loading ? "none" : "rgba(7, 21, 44, 0.1)"};
                  color: ${$loading
                      ? "var(--color-violet-500)"
                      : "var(--color-neutral-300)"};
              `
            : css`
                  ${$selected
                      ? css`
                            background: var(--color-neutral-700);
                            color: var(--color-white);
                        `
                      : css`
                            background: rgb(7 21 44 / 40%);
                            color: var(--color-white);

                            &:hover {
                                background: rgb(7 21 44 / 50%);
                            }

                            &:active {
                                background: rgb(7 21 44 / 60%);
                            }

                            &.active {
                                background: var(--color-neutral-700);
                                color: var(--color-white);
                            }
                        `}
              `}
`;

const buttonsKinds = {
    primary: styled(BasicButton)`
        ${primaryStyle}
    `,
    secondary: styled(BasicButton)`
        ${secondaryStyle}
    `,
    ghost: styled(BasicButton)`
        ${ghostStyle}
    `,
    success: styled(BasicButton)`
        ${successStyle}
    `,
    destructive: styled(BasicButton)`
        ${destructiveStyle}
    `,
    warning: styled(BasicButton)`
        ${warningStyle}
    `,
    "ghost-reverse": styled(BasicButton)`
        ${ghostReverseStyle}
    `,
    "secondary-reverse": styled(BasicButton)`
        ${secondaryReverseStyle}
    `,
    action: styled(BasicButton)`
        ${actionStyle}
    `,
    dark: styled(BasicButton)`
        ${darkStyle}
    `,
} as const;

export type ButtonBaseProps = {
    readonly kind: ButtonKind;
    readonly loading?: boolean;
    readonly selected?: boolean;
    readonly size?: ButtonSize;
    readonly prefix?: ReactNode;
    readonly suffix?: ReactNode;
    readonly icon?: ReactNode;
};

export const useButtonContent = ({
    children,
    loading = false,
    size,
    prefix,
    suffix,
    icon,
}: ButtonBaseProps &
    Record<string, unknown> & {
        children?: ReactNode;
    }): JSX.Element => {
    const hasIcon = Boolean(icon);

    return (
        <>
            <Content>
                {hasIcon ? (
                    icon
                ) : Boolean(prefix) || Boolean(suffix) ? (
                    <>
                        {prefix}
                        {children ? <span>{children}</span> : undefined}
                        {suffix}
                    </>
                ) : (
                    children
                )}
            </Content>
            {loading ? (
                <Loader
                    height={size === "small" ? "20" : "24"}
                    width={size === "small" ? "20" : "24"}
                />
            ) : null}
        </>
    );
};

export const Button = forwardRef<
    HTMLButtonElement,
    ButtonBaseProps &
        Omit<ButtonHTMLAttributes<HTMLButtonElement>, "prefix" | "suffix">
>((props, ref): JSX.Element => {
    const {
        kind,
        disabled = false,
        loading = false,
        selected = false,
        size,

        children,

        prefix,

        suffix,
        icon,
        ...buttonAttributes
    } = props;

    const Component = buttonsKinds[kind];

    const content = useButtonContent(props);

    return (
        <Component
            {...buttonAttributes}
            $disabled={disabled || loading}
            $loading={loading}
            $selected={selected}
            $size={size}
            $square={Boolean(icon)}
            disabled={disabled || loading}
            ref={ref}
        >
            {content}
        </Component>
    );
});

Button.displayName = "Button";
